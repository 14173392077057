import React from "react"
import ShareBlog from "../components/share-blog"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderLargeText, SubHeaderText } from "../elements"
import DynamicZone from "../components/dynamic-zone"
import Breadcrumbs from "../components/breadcrumbs"

const IndexPage = props => {
  const { title, content, description, breadcrumbs } = props.pageContext

  return (
    <Layout breadcrumb={breadcrumbs}>
      {Breadcrumbs && (
        <Breadcrumbs link={"/blogs"} text={"Back to all blogs"} />
      )}
      <SEO title={title} imageShare={content.Image ? content.Image.publicURL : undefined} />
      <HeaderLargeText>{title}</HeaderLargeText>
      <SubHeaderText
        dangerouslySetInnerHTML={{ __html: description }}
      ></SubHeaderText>
      <DynamicZone components={content.Content} />
      <ShareBlog />
    </Layout>
  )
}

export default IndexPage
