import React from "react"
import styled from "styled-components"
import { HeaderText } from "../elements"
import { window } from "browser-monads"

// Social Media Icons
import fbunselected from "../assets/images/icons/social/FB_unselected_grey.svg"
import twitterunselected from "../assets/images/icons/social/twitter_unselected_grey.svg"

const StyledDiv = styled.div`
  display: grid;
  max-width: 230px;
  margin: 0 auto;
  .social-icons {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    margin: 0 auto;
    gap: 10px;
    margin-bottom: 40px;
    .fb {
      width: 50px;
      height: 50px;
      background: url(${fbunselected});
      background-size: cover;
    }
    .twitter {
      width: 50px;
      height: 50px;
      background-image: url(${twitterunselected});
      background-size: cover;
    }
  }
`

const ShareBlog = () => {
  return (
    <StyledDiv>
      <HeaderText>Share this blog</HeaderText>
      <div className="social-icons">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
          className="fb"
          target="_blank"
          rel="noreferrer"
        />
        <a
          href={`https://twitter.com/intent/tweet?text=Check%20this%20out&url=${window.location.href}&`}
          className="twitter"
          target="_blank"
          rel="noreferrer"
        />
      </div>
    </StyledDiv>
  )
}

export default ShareBlog
